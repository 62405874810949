import PropTypes from "prop-types";
import React from "react";
import WhiteIsoLogoFooter from "../images/white-isologo-footer.svg";
import IconFooterX from "../images/icon-X.svg";
import IconFooterLinkedin from "../images/icon-linkedin.svg";
import IconFooterInstagram from "../images/icon-instagram.svg";
import IconFooterFacebook from "../images/icon-facebook.svg";
import IconFooterVimeo from "../images/icon-vimeo.svg";
import PrismicLink from "../components/prismicLink";
import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

const query = graphql`
  query FooterQuery {
    site {
      id
    }
    prismicSiteSettings {
      _previewable
      data {
        social_twitter {
          url
        }
        social_medium {
          url
        }
        social_linkedin {
          url
        }
        social_instagram {
          url
        }
        social_facebook {
          url
        }
        social_vimeo {
          url
        }
        footer_navigation {
          footer_navigation_link {
            url
          }
          footer_navigation_name {
            text
          }
        }
        footer_text {
          richText
        }
      }
    }
  }
`;

const Footer = () => {
  const staticData = useStaticQuery(query);
  const data = useMergePrismicPreviewData(staticData);

  const linkX = data?.prismicSiteSettings?.data?.social_twitter;
  const linkLinkedin = data?.prismicSiteSettings?.data?.social_linkedin;
  const linkInstagram = data?.prismicSiteSettings?.data?.social_instagram;
  const linkFacebook = data?.prismicSiteSettings?.data?.social_facebook;
  const linkVimeo = data?.prismicSiteSettings?.data?.social_vimeo;
  const footerNav = data?.prismicSiteSettings?.data?.footer_navigation || [];

  const footLinks = (cls) => {
    return (
      <p className={cls ? cls : ""}>
        <span>© Modus Associates LLC</span>{" "}
        <span>
          {footerNav.map((i, idx) => (
            <React.Fragment key={idx}>
              <a href={i.footer_navigation_link.url}>
                {i.footer_navigation_name?.text}
              </a>
              {idx < footerNav.length - 1 ? " / " : ""}
            </React.Fragment>
          ))}
        </span>
      </p>
    );
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <footer
      className="footer"
      onFocus={() => {
        window.scrollTo(0, document.body.scrollHeight);
      }}
      role="contentinfo"
    >
      <div className="container">
        <div className="footer__top">
          <div className="footer__top__row">
            <div className="footer__top__logo">
              <WhiteIsoLogoFooter />
            </div>
            <div className="footer__text">
              <h2 className="h2">Let's craft your digital future, together.</h2>
              <div>
                <a href="/contact">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="container">
          <div className="footer__bottom__row">
            {footLinks("hidden-mobile")}
            <div className="footer__social">
              {linkLinkedin && (
                <PrismicLink link={linkLinkedin}>
                  <IconFooterLinkedin />
                  <span className="social-text">Linkedin</span>
                </PrismicLink>
              )}
              {linkFacebook && (
                <PrismicLink link={linkFacebook}>
                  <IconFooterFacebook />
                  <span className="social-text">Facebook</span>
                </PrismicLink>
              )}
              {linkInstagram && (
                <PrismicLink link={linkInstagram}>
                  <IconFooterInstagram />
                  <span className="social-text">Instagram</span>
                </PrismicLink>
              )}
              {linkX && (
                <PrismicLink link={linkX}>
                  <IconFooterX />
                  <span className="social-text">X</span>
                </PrismicLink>
              )}
              {linkVimeo && (
                <PrismicLink link={linkVimeo}>
                  <IconFooterVimeo />
                  <span className="social-text">Vimeo</span>
                </PrismicLink>
              )}
            </div>
            {footLinks("hidden-desktop")}
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

export default Footer;
