import React, { useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import PrismicLink from "./prismicLink";
import ArrowSvg from "./arrowSvg";

const query = graphql`
  query GlobalHeaderPromoBannerQuery {
    site {
      id
    }
    prismicSiteSettings {
      data {
        global_header_promo_banner_active
        global_header_promo_banner_bold_text
        global_header_promo_banner_light_text
        global_header_promo_banner_link {
          url
        }
      }
    }
  }
`;

const GlobalHeaderPromoBanner = () => {
  const staticData = useStaticQuery(query);
  const data = useMergePrismicPreviewData(staticData);
  const bannerActive = data?.prismicSiteSettings?.data?.global_header_promo_banner_active;
  const bannerBoldText = data?.prismicSiteSettings?.data?.global_header_promo_banner_bold_text;
  const bannerLightText = data?.prismicSiteSettings?.data?.global_header_promo_banner_light_text;
  const bannerLink = data?.prismicSiteSettings?.data?.global_header_promo_banner_link;
  const bannerLinkRef = useRef(null);
  useEffect(() => {

    const bannerLinkHeight = bannerLinkRef?.current?.offsetHeight;
    if (!bannerLinkHeight) return;
    document.documentElement.style.setProperty('--banner-height', `${bannerLinkHeight}px`);

  }, []);

  return (
    <>
      {
        bannerActive &&
        <div className="global-header-promo-banner" role="contentinfo"  ref={bannerLinkRef}>
          <PrismicLink link={bannerLink} className="global-header-promo-banner__link">
            <div className="container">
              <div className="global-header-promo-banner__content">
                <div className="global-header-promo-banner__text__marquee">
                  <p><strong>{(bannerBoldText)}&nbsp;&nbsp;</strong>{(bannerLightText)}</p>
                </div>
                <ArrowSvg width={50} viewBox="13 0 1 18" />
              </div>
            </div>
          </PrismicLink>
        </div>
      }
    </>
  );
};


export default GlobalHeaderPromoBanner;
