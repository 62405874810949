const linkResolver = (link) => {
  if (!link) {
    // console.warn("Link Resolver: No link provided", link);
    return "/";
  }
  if (link.type) {
    switch (link.type) {
      case "focus_area":
        return `/focus-area/${link.uid}/`;
      case "page":
        return `/${link.uid}/`;
      case "project":
        return `/work/${link.uid}/`;
      case "medium_post":
        return `/blog/${link.uid}/`;
      case "homepage":
        return `/`;
      default:
        // console.warn("Link Resolver: link did not match any type", link);
        return "/";
    }
  } else if (link.url) {
      if (link.url.split('/expertise-')[1]) {
        return `/expertise/${link.url.split('/expertise-')[1]}`
      } else {
        return link?.url?.replace("https:///", "/");
      }
  } else {
    // console.warn("Link Resolver: link did not match any type", link);
    return "/";
  }
};

module.exports = linkResolver;
